import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';

const FooterStyles = styled.footer`
  background-color: LightBlue;
  .social-icons a.button {
    background-color: transparent;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    margin: 0 0.8rem;
    color: white;
  }
  .copyright {
    margin-top: 0.75rem;
  }
  .technology {
    margin-top: 0.75rem;
  }
`;

export default function Footer() {
  return (
    <>
      <FooterStyles className="section footer is-primary is-small has-text-centered">
        <div className="container is-narrow">
          <div className="social-icons">
            <p className="field">
              <a
                className="button is-medium"
                href="https://twitter.com/eyetour"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a
                className="button is-medium"
                href="https://www.linkedin.com/in/keith-adams-1aa5b233/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </p>
          </div>
          <div className="copyright">
            <FontAwesomeIcon icon={faCopyright} /> {new Date().getFullYear()}{' '}
            kda software consultants ltd
          </div>
          <div className="technology">Built with gatsbyjs, contentful and bulma</div>
        </div>
      </FooterStyles>
    </>
  );
}
