import React from 'react';

export default function Header({ children }) {
  return (
    <>
      <section className="container">
        <h1 className="title">kda software consultants</h1>
        <p className="subtitle">architect, design, develop, deploy!</p>
      </section>
    </>
  );
}
